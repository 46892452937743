<!--
 * @Description: 停车场运营系统 车场管理 出入口信息 inwardOutwardInfo
 * @Author: GeChang
 * @Github: https://github.com/hzylyh
 * @Date: 2021-03-27 14:34:14
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainBody">
    <!--上半部查询-->
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="停车场">
              <el-select v-model="searchForm.ParkingName"
                         placeholder="请选择">
                <el-option v-for="item in options"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-row class="topButton">
              <el-button type="primary"
                         @click="onSubmit">查 询</el-button>
              <el-button type="info"
                         style="margin-left:17px"
                         @click="resetForm">重 置</el-button>
            </el-row>
          </el-col>
        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>出入口信息</span>
        <span class="tableTitleButton topButton">
          <el-button type="success">新 增</el-button>
          <el-button type="info"
                     style="margin-left:17px">导出</el-button>
          <el-button type="danger"
                     style="margin-left:17px">批量删除</el-button>
        </span>
      </el-row>
      <el-row class="tableContent">
        <!-- 表格 -->
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :paginationConfig='paginationConfig'
                  :tableConfig='tableConfig'
                  :config='tableConfig'
                  @getList="getList">
          <template slot="isFirst">
            <el-table-column type="selection"></el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-row>
                  <el-col :span="8">
                    <el-button @click="handleClick(scope.row)"
                               type="text"
                               size="medium">查看</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button type="text"
                               size="medium">编辑</el-button>
                  </el-col>
                </el-row>

              </template>
            </el-table-column>

          </template>
        </dt-table>
      </el-row>
    </el-row>
  </div>
</template>

<script>

import dtTable from "@/components/table.vue";
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable
  },
  data () {
    //这里存放数据
    return {
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableConfig: { border: true },
      listMap: {
        pageNum: 1,
        pageSize: 10,
        chargCode: "",
        chargName: "",
        operName: "",
      },
      tableColumnList: [
        {
          prop: "parkingName",
          label: "停车场名称",
        },
        {
          prop: "parkingTypeName",
          label: "停车场类型名称",
        },
        {
          prop: "entryExitTypeName",
          label: "出入口类型名称",
        },
        {
          prop: "entryExitName",
          label: "出入口名称",
        },
        {
          prop: "entryExitDescription",
          label: "出入口描述",
        },
      ],
      tableList: {
        list: [
          {
            parkingName: '鲁能新城八街停车场',
            parkingTypeName: '地面停车场',
            entryExitTypeName: '入口',
            entryExitName: '东门地面入口',
            entryExitDescription: '地下停车场西门地库'
          },
          {
            parkingName: '正平路停车场',
            parkingTypeName: '地下停车场',
            entryExitTypeName: '出口',
            entryExitName: '西门地面出口',
            entryExitDescription: '地下停车场东门地库'
          },
          {
            parkingName: '兴盛大道停车场',
            parkingTypeName: '地下停车场',
            entryExitTypeName: '出口',
            entryExitName: '东门地面出口',
            entryExitDescription: '地下停车场南门地库'
          },
        ],
      },
      searchForm: {},
      total: 10,
      options: [
        { label: "一号停车场", value: 1 },
        { label: "二号停车场", value: 2 }
      ],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      value1: '',
      value2: ''
    };
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  //方法集合
  methods: {
    getList () {
      console.warn('获取内容')
    },
    handleClick () { },
    onSubmit () { },
    resetForm () { },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发

};
</script>
<style lang='scss' scoped>
$topSelectHeight: 85px;
//@import url(); 引入公共css类
//斑马线
/deep/.warning-row {
  background: #063159 !important;
}
/deep/.success-row {
  background: #032244 !important;
}
// 最外层div
.mainBody {
  height: 100%;
  // 顶部查询条件
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 30px;
      // margin-top: 16px;
    }
  }
  .tableRow {
    width: 100%;
    // height: calc(100% - #{$topSelectHeight});
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .topButton {
        display: flex;
        padding-left: 30px;
      }
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
</style>
